@import url('https://fonts.googleapis.com/css2?family=Amiko:wght@400;600;700&display=swap');

*{
  font-family: 'Amiko', sans-serif;
}

:root{
  --TituloPrincipal: 32px;
  --ParrafoEscritorio: 18px;
  
  --TituloMovil: 22px;
  --ParrafoMovil: 16px;
  --TextoColor: #333333;
  --Negritas: Bold;

  --VerdePKT1: #3baf29;
  --AzulPKT1: #151b4e;
  --AzulHeader: #2f3768;
  --Rojo: #F40000;
  --Blanco: #fff;
  --Negro: #000;

  --Fondo: #eff0f2;

}


/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #151b4e;
  border-radius: 5px;
  /* display: none; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #151b4ec0;
  opacity: 0.1;
}
.body-abnoFacts::-webkit-scrollbar {
  width: 0px;
}

/* quitarle las flechas al input tipo number */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}